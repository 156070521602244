<template>
  <section id="registration_step">
    <div class="w800">
      <div class="bar_outer">
        <div class="realbar"
          :style="`width:${step/2.9*100}%;`">
        </div>
        <div class="railbar"></div>
      </div>
      <div class="step_name">
        <p class="small txt-dark_gray"
          v-for="(item, key) in setpName"
          :key="key"
          :class="{active : step >= key}">
            {{item}}
          </p>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/registration.scss';

export default {
  name: 'RegistrationStep',
  props: ['step'],
  data() {
    return {
      setpName: [
        this.$t('registrationClinic'),
        this.$t('selectDoctorAndTime'),
        this.$t('comfirmRegistration'),
        this.$t('registrationComplete'),
      ],
    };
  },
};
</script>
